.landing-header {
    &--container {
        @apply flex flex-col min-h-screen;
    }

    &--background {
        height: 42.5rem;
        @apply w-full;
    }

    &--information {
        @apply absolute w-full text-white lg:grid lg:grid-cols-2 xs:m-0 xs:flex xs:w-full xs:justify-center;
    }

    &--title {
        width: 42.5rem;
        @apply font-poppins-bold text-title xs:text-xxl xs:text-center ;
    }

    &--image {
        @apply flex items-center justify-center w-full xs:ml-0 ;
        margin-top: 16.5625rem;
    }

    &--logo {
        @apply flex absolute justify-end w-full xs:hidden;
        margin: 2.1875rem 0 0 -3.3125rem;
    }
}

.landing-body {
    @apply xs:m-0 xs:px-5 xs:mb-10 px-10;

    &-information--logo {
        width: 14.59rem;
        height: 9.1375rem;
    }

    &-information--container {
        @apply flex xs:flex-col w-full justify-around;
    }

    &--information {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4.6875rem;
        @apply xs:mt-10 ;
    }

    &--text {
        width: 27.5rem;
        padding-left: 1.875rem;
        margin-top: 2.5rem;
        @apply text-gray border-l border-solid border-green-light xs:w-full xs:border-none xs:p-0 xs:text-center font-normal;

        &--span-green {
            @apply text-green-light font-semibold;
        }

        &--span-blue {
            @apply text-blue font-semibold;
        }
    }

    &--benefits {
        @apply xs:mt-10 ;
        &-container {
            @apply flex items-center xs:flex-col text-center;
        }

        &-information {
            width: 20.5625rem;
            @apply xs:w-full;
        }

        h1 {
            margin-left: 0.3125rem;
            @apply font-poppins-bold text-blue text-subtitle md:text-2.45xl lg:text-largue  xs:ml-0 xs:text-xxl  md:ml-14 text-center ;

        }

        h2 {
            margin-bottom: 0.25rem;
            @apply font-poppins-bold text-green-light xs:text-xxl ;
        }

        p {
            @apply text-gray-dark text-sm
        }

        span {
            @apply font-poppins-bold;
        }
    }


}

.landing-modal {
    width: 380px;
    @apply flex flex-col items-center xs:px-2;
     &--information {
        @apply text-center mb-7;
        h1 {
            @apply text-green text-xxl font-poppins-bold;
        }
        span {
            @apply text-blue;
        }
     }
     &--button {
        width: 11.25rem;
        min-height: 2.5rem;
        padding: 0.3125rem 0;
        border-radius: 2.125rem;
        line-height: 1.25rem;
        margin-top: 1.125rem;
        @apply bg-purple-dark text-white flex justify-center items-center text-sm font-poppins-bold;
     }
}

@media (max-width: 80rem) {
    .landing-header--image {
        margin-top: 16.5625rem;
    }
}

@media (max-width: 48rem) {
    .landing-header--background {
        height: 21.875rem;
    }
    .landing-header--title {
        width: 16.875rem;
        margin-top: 2rem;
    }
    .landing-header--image {
        margin: 10.625rem 0 0;
    }
}

