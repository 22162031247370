.landing-footer {
    &--container {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }

    &--background {
        width: 100%;
        height: 13.75rem;
    }

    &--body {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 6rem;
        @apply  xs:mt-28;
    }

    &--information {
        color: white;
        margin-top: 1rem;
        text-align: center;
        @apply xs:px-2 xs:text-xs xs:mt-10;
    }

    &--icon {
        cursor: pointer;
    }
}


@media (max-width: 48rem) {
    .landing-footer--background {
        height: 300px;
    }
}

