	.background {
		background-color: rgba(11, 44, 76, .8);
        
	}

	.modal--full {
		@apply xs:w-full
		xs:h-full
		xs:rounded-none
		xs:bg-white;
	}

	.scroll-modal::-webkit-scrollbar{
		display: none;
	}

	.modal--response {
		@apply xs:flex xs:flex-col xs:justify-center xs:mx-16 xs:h-screen xs:mt-14 xs:mb-14 md:flex-none md:mx-0 md:h-auto ;	
	}

	.header__icon {
		@apply mr-2 text-blue self-center;
	}

	.footer__button {
		&--blue {
			@apply w-40 p-2 text-xs font-bold rounded-lg bg-blue text-white xs:max-w-max xs:px-8;
			@media (max-width: 767px) {
				max-width: 8.375rem;
				height: 2.063rem;
			}
			min-width:8.375rem;
		}

		&--gray-light {
			@apply w-40 p-2 text-xs font-bold rounded-lg bg-gray-light text-blue xs:max-w-max xs:px-12;
			@media (max-width: 767px) {
				max-width: 8.375rem;
				height: 2.063rem;
			}
		}
	}
