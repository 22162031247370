.btn {
  @apply font-poppins
    h-7.5  w-40 
    flex justify-center items-center
		rounded
		leading-tiny
    text-white text-sm font-bold;
  &:hover {
    @apply text-white bg-blue;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 48rem) {
    width: 8.5625rem;
  }
}
