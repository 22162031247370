.wrapper-box {
	@apply  bg-transparent flex flex-col items-center relative;
}


.checkmark {
	@apply rounded absolute left-0 h-4.5 w-4.5 border border-solid pointer-events-auto;

	&:hover {
		@apply bg-gray-light cursor-pointer;
	}

	&::after {
		@apply absolute hidden;
		content: "";
	}
}

.container {
	@apply flex relative pl-7 text-base font-normal leading-sm select-none text-gray h-auto;
	// min-height: 1.125rem;

	&--father {
		@apply items-center;
	}

	&--child {
		@apply items-start pointer-events-none;
	}

	&--standard {
		@apply relative pl-7 cursor-pointer text-sm font-bold leading-sm select-none text-gray-dark h-auto w-52;

		input {
			@apply block absolute opacity-0 cursor-pointer h-0 w-0;
		}

		&:hover input ~ .checkmark {
			@apply bg-gray-light;
		}

		& input:checked ~ .checkmark {
			@apply bg-blue border-blue;
		}

		& input:checked ~ .checkmark:after {
			@apply block;
		}
	}

	&--disabled {
		@apply relative pl-7 text-sm font-bold leading-sm select-none text-gray-dark h-auto w-52;

		input {
			@apply block absolute opacity-0 h-0 w-0;
		}
	}

	input {
		@apply block absolute opacity-0 cursor-pointer h-0 w-0;
	}

	& input:checked ~ .checkmark {
		@apply bg-blue border-blue cursor-pointer;
	}

	& input:disabled ~ .checkmark {
		@apply bg-gray-light border-gray cursor-default;
	}

	& input:checked ~ .checkmark:after {
		@apply block;
	}
}

.checkmark-light {
	@apply border border-solid border-gray;
}

.small-checkbox {
	width: min-content !important;
}