.button-landing {
    &--container {
        @apply xs:flex xs:w-full xs:justify-center;
    }
    &--information {
        width: 11.25rem;
        min-height: 2.5rem;
        padding: 0.3125rem 0;
        border-radius: 2.125rem;
        line-height: 1.25rem;
        margin-top: 2.625rem;
        @apply text-center cursor-pointer bg-purple-dark text-white xs:mt-4 ;

        span {
            @apply font-poppins-bold underline;
        }
    }
    
}

.button-landing-access {
    &__information {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11.25rem;
        height: 50px;
        padding: 0.3125rem 0;
        border-radius: 2.125rem;
        line-height: 1.25rem;
        margin-top: 2.625rem;
        @apply text-center cursor-pointer bg-purple-dark text-white xs:mt-4 ;

        span {
            @apply font-poppins-bold;
        }
    }
    
}

