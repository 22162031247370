.description-diggipymes {
    @apply mb-24 max-w-113.25 ml-10.5 mr-42 xs:mr-0 xs:w-full xs:px-9.75 xs:flex xs:flex-col xs:items-center xs:mb-0;
}

.container-software-development {
    @apply mt-16 flex justify-center xs:mx-0 items-center xs:flex-col xs:mt-9.75 xs:relative xs:px-9;
}

.icon-container-mobile {
    @apply absolute -bottom-6 max-h-75 max-w-37.5 left-18 w-full h-full xs:-bottom-2 xs:left-33.125 xs:w-18 xs:h-33.5;
}

.container-consultancy {
    @apply mt-24.25 flex justify-center items-center ml-5 mr-23.5 xs:px-3 xs:flex-col-reverse xs:mt-29.5 xs:mr-0 xs:ml-0;

    &__container--cards {
        @apply relative xs:flex xs:pb-2 xs:mt-8 xs:overflow-x-hidden mr-23.5 xs:mr-0 xs:w-full;
    }

    &__cards--responsive {
        @apply max-h-26 max-w-31 rounded-4.5 mr-2 p-2 flex flex-wrap justify-center gap-x-3 gap-y-8;
    }

    &__title--responsive {
        @apply text-gray font-medium text-xtiny text-center mt-2;
    }

    &__icon--responsive {
        @apply flex flex-col justify-center items-center w-34 lg:m-0;
    }

    &__cards {
        @apply max-h-full max-w-163.5 rounded-4.5 p-4.5 flex flex-wrap justify-center gap-x-3 gap-y-8;
    }

    &__title {
        @apply text-gray font-medium text-xs text-center mt-2;
    }

    &__container--icon {
        @apply flex flex-col justify-center items-center w-34 lg:m-0;
    }
}

.icon-arrow-responsive {
    @apply w-5.5 h-8.5 absolute cursor-pointer top-30;
}

.class-name-carousel {
    @apply absolute z-10 flex items-center justify-center w-full text-lg font-semibold text-white font-poppins;
}

.container-modal-about-us {
    @apply relative flex flex-wrap items-center justify-center h-full gap-x-4.5  gap-y-4;
}

.modal-about-us {
    @apply z-50 bg-gray-light xs:h-screen max-w-max-mi flex flex-col lg:min-w-min-mi shadow-templateDesign rounded-2xl
  p-10 xs:overflow-y-scroll xs:p-2;
}

.container-modal::-webkit-scrollbar {
    @apply w-1 h-1.5;
}

.container-modal::-webkit-scrollbar-track {
    @apply bg-green bg-opacity-20 rounded-md;
}

.container-modal::-webkit-scrollbar-thumb {
    @apply bg-green rounded-md xs:bg-opacity-70;
}

.class-container-form {
    @apply max-w-162.5 w-full xs:px-4.5 px-6 pt-4 pb-7 text-center border flex flex-col items-center rounded-lg border-green border-solid xs:border-none;

    &__title {
        @apply text-xxl text-green mb-3 mt-3 xs:mb-2.25 xs:text-xlgx;
    }

    &__text--responsive {
        @apply absolute invisible w-0 text-left text-gray text-tiny xs:visible xs:relative xs:w-full;
    }

    &__content--inputs {
        @apply flex flex-wrap justify-center h-full gap-x-7 gap-y-2 lg:justify-center;
    }

    &__text--inputs {
        @apply lg:text-gray-contact xs:text-gray-contact md:text-gray-contact;
    }
}

.form-text-inputs {
    @apply lg:text-gray-contact xs:text-gray-contact md:text-gray-contact;
}

.radio {
    @apply hidden;
}

.slider {
    @apply h-83.75 lg:max-w-119.25 w-full my-0 relative md:max-w-90 xlg:max-w-119.25;

    transform-style: preserve-3d;
}

.slider label {
    @apply m-auto rounded absolute cursor-pointer transition;
}

.slider-input-1:checked ~ .slide-3, .slider-input-2:checked ~ .slide-1,
.slider-input-3:checked ~ .slide-2 {
    @media (min-width: 47.9375rem) {
        transform: translate3d(-50%, 0, -6.25rem);
    }
}

.slider-input-1:checked ~ .slide-1, .slider-input-2:checked ~ .slide-2,
.slider-input-3:checked ~ .slide-3 {
    @media (min-width: 47.9375rem) {
        transform: translate3d(0, 0, 0);
    }
}

.slider-input-1:checked ~ .slide-2, .slider-input-2:checked ~ .slide-3,
.slider-input-3:checked ~ .slide-1 {
    @media (min-width: 47.9375rem) {
        transform: translate3d(50%, 0, -6.25rem);
    }
}

.Link-btn {
    @apply font-poppins bg-green
      h-7.5 w-40
      rounded-lg
      leading-tiny
      flex justify-center items-center
      text-white text-sm font-bold;

    &:hover {
        @apply text-white bg-blue;
    }
}

.image-opacity {
    filter: opacity(0.7) drop-shadow(0 0 0 rgb(11, 44, 76));
}

.image-opacity-active {
    filter: opacity(1);
}

.image-zoom:hover {
    transition: transform 1s ease-in;
    transform: scale(1.05);
}

.bg-white-scrollbar {
    &::-webkit-scrollbar {
        @apply w-1.5 h-1.5;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 169, 158, 0.458);

        @apply rounded-md;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-green-light rounded-md xs:bg-opacity-70;
    }

    // scrollbar in firefox
    scrollbar-width: thin;
    scrollbar-color: green white;
}

.section-description {
    @apply w-full flex justify-center items-center flex-col;
    height: 20.6875rem;
    @media (max-width: 54.375rem) {
        height: 22.5rem;
        
    }
}

.title-contact {
    @apply w-76.25 h-15 text-4xl;
}

.section-solutions {
    @apply w-full flex justify-center items-center flex-col bg-gray-background;

    height: 35.9375rem;
    @media (max-width: 54.375rem) {
        height: 40rem;
        
    }
}

.scroll-form-contact {
    &::-webkit-scrollbar {
        width: 0.18rem;
    }
}

.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
  }

  .carousel-track {
    display: flex;
    animation: scroll 25s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-12.5rem * 5)); /* Ancho de las 5 imágenes originales */
    }
  }
  
  .carousel-slide {
    flex: 0 0  auto;
    padding-right: 3rem;
    display: flex;
    justify-items: center;
    align-items: center;
  }
