@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    @font-face {
        font-family: Poppins;
        font-weight: 400;
        src: url("./assets/fonts/Poppins-Regular.ttf");
    }

    @font-face {
        font-family: Poppins_Bold;
        font-weight: 700;
        src: url("./assets/fonts/Poppins-Bold.ttf");
    }

    * {
        box-sizing: border-box !important;
    }

    html,
    body,
    #root {
        height: 100%;

        @apply font-poppins;

        &::-webkit-scrollbar {
            @apply w-1.5 h-1.5;
        }

        &::-webkit-scrollbar-thumb {
            @apply bg-green-light rounded-md;
        }

    }

}
