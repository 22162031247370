    .input {
        &--container {
            @apply flex h-8.25 rounded-md  w-full bg-transparent items-center text-sm;

            input {
                @apply w-full px-2 outline-none focus:outline-none bg-transparent;
            }

            &:focus-within {
                .icon {
                    @apply z-10 rotate-180 cursor-pointer;
                }
            }
        }

        &--required {
            --tw-border-opacity: 1;
            border-color: rgba(129, 49, 155, var(--tw-border-opacity)) !important;
        }
    }

    .border-purple {
        --tw-border-opacity: 1;
        border-color: rgba(129, 49, 155, var(--tw-border-opacity)) !important;
    }

    .border-form-focus {
        &:focus-within {
            --tw-border-opacity: 1;
            border-color: rgba(129, 49, 155, var(--tw-border-opacity)) !important;
        }
    }

    textarea-padding {
        padding: 0.8rem 0rem 0rem 0.5rem;
    }

    .arrow-affair {
        width: 0.591rem;
    }

    .adaptable-text-area {
        @apply  rounded-md  w-full bg-transparent justify-center text-sm xs:text-tiny focus:outline-none relative pl-2 pt-1.5;
        min-height: 2rem;
    }
    
    .text-error{
        @apply self-end text-tiny mr-1.5 text-right leading-xtiny mt-1;
        display: block;
        color: rgba(129, 49, 155, 1);
    }

    .option-select {
        @apply font-poppins;
        border-bottom-width: 0.063rem;
        --tw-border-opacity: 1;
        border-color: rgba(174, 174, 175, var(--tw-border-opacity));
    }
