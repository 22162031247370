.information {

	&__title {
		@apply mb-2 flex flex-row items-center font-poppins text-blue;
	}

	&__description {
		@apply m-0 text-gray font-poppins;
	}
}
